<template>
  <dash-page-new
      :title="topic.title || ''"
      subtitle="Documentation Topic Editor"
      icon="mdi-help-circle"
      root="Documentation"
      :root-location="localeLink('dashboard/documentation')"
  >

    <template #header_action>
      <ws-select width="90px" v-model="selectedLang"  :items="[{ text : 'UA' , value : 'ua' }, { text : 'RU' , value : 'ru' } , { text : 'EN' , value : 'en' }]" ></ws-select>
      <v-btn
          height="40"
          v-if="!topic.published"
          @click="publishTopic"
          :color="wsACCENT"
          :dark="!topic.is_published"
          :outlined="topic.is_published"
          class="noCaps ml-4"  >
        {{ !topic.is_published ? 'Publish' : 'Hide' }}
      </v-btn>
    </template>

    <template #header_title_edit>
      <v-btn icon :color="wsACCENT" class="noCaps" @click="displayDialog = true"><v-icon>mdi-pencil</v-icon></v-btn>
    </template>


    <template #default>

      <ws-text-field
          v-model="topic.alias"
          @change="editTopicContent"
          class="mt-6"
          label="Alias"
          placeholder="Enter topic alias" />

      <ws-text-field
          v-model="topic.description"
          @change="editTopicContent"
          class="mt-6"
          area
          label="Short Description"
          placeholder="Enter short description" />

      <h5 class="font-weight-medium py-4" :style="`color : ${wsACCENT}`">Content : </h5>
      <v-sheet class="wsRoundedLight pa-4 pb-16 mb-16" :style="`border : 1px solid ${wsDARKLIGHT}`">
        <wsTextEditor
            v-model="topic.content"
            :html="topic.html"
            @input="editTopicContent"
            is-public
            superadmin
        />
      </v-sheet>
      <v-sheet height="200">

      </v-sheet>

    </template>

    <template #dialog>
      <ws-dialog
          v-model="displayDialog"
          @save="editTopicContent"
          title="Edit topic name"
      >
        <ws-text-field v-model="topic.title" label="Title" placeholder="Enter topic title"  />
      </ws-dialog>
    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "documentationTopicEditor",
  props : {
    uuid : {
      type : String
    }
  },
  data() {
    return {
      selectedLang : 'ua',
      topic : {},
      displayDialog : false,
    }
  },
  watch : {
    selectedLang() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('documentation', [
      'GET_TOPIC',
      'ADD_TOPIC',
      'EDIT_TOPIC'
    ]),


    publishTopic() {
      this.topic.is_published = !this.topic.is_published
      this.editTopicContent()
    },
    async editTopicContent() {
      let data = JSON.parse(JSON.stringify(this.topic))
      data.lang = this.selectedLang
      let result = await this.EDIT_TOPIC(data)
      if ( !result ) {
        return
      }
      this.displayDialog = false
    },

    async initPage() {
      let result = await this.GET_TOPIC({ uuid : this.uuid , lang : this.selectedLang } )
      if ( !result ) {
        return
      }
      this.topic = result
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>